import TokenStore from '@common/dbStorage/tokenStore';
import TokenDetail from '@common/token/tokenDetail';
import {
	TOKEN_STORE,
	TOKEN_NAME_ESI,
	TOKEN_NAME_GRAPH
} from '@common/constants';
import { OfficeProxy } from '@egress/officejs-proxy';
import { renewAuthTokens } from '@common/httpClient/auth/client';
import StorageError from '@common/errors/storageError';
import { logger } from '@common/logger';
import hash from 'object-hash';
import AuthError from '@common/errors/authError';
import { EmailAssertionPair } from '@common/interfaces';

/**
 * Retrieves cached Graph token for the given username or gets a new token and caches
 * @param {string} upn The currently logged in username
 * @returns {string | null | StorageError}
 */
async function getGraphToken(upn: string): Promise<string> {
	if (!upn) throw new StorageError('getGraphToken: upn not provided');

	// Token name is the constant appended with a hash of the username.
	// This way we get the correct token if the user switches accounts.
	const tokenName = `${TOKEN_NAME_GRAPH}-${hash(upn)}`;
	const tokenStore = new TokenStore(TOKEN_STORE);
	const cachedToken = await tokenStore.getToken(tokenName);

	if (!cachedToken || cachedToken.expiresWithinMinutes(5)) {
		// No cached token, or it's due to expire soon. Get us a new one.
		logger.info({
			message: 'Graph token not found in token store or is expiring/expired, '
				+ 'requesting new one.',
			properties: { source: 'token\\index.js', method: 'getGraphToken' }
		});
		const officeProxy = new OfficeProxy();
		let newToken:string | null = null;

		// Only cache the token if it is for the currently active user
		if (upn && upn === officeProxy.getUserProfile().emailAddress) {
			newToken = await officeProxy.getGraphTokenAsync();
			// store newToken
			await tokenStore.updateToken(new TokenDetail(tokenName, newToken));
		} else {
			// if there is a upn but it is not the current user, return the token for the current user
			// getGraphTokenAsync only works for current user
			newToken = await getGraphToken(officeProxy.getUserProfile().emailAddress);
		}

		return newToken;
	}
	return cachedToken.data;
}

/**
 * Attempts to get graph tokens for the supplied email addresses
 * @param {string[]} upns The email addresses for which to get tokens
 * @returns {EmailAssertionPair[]} The email addresses with their tokens if available
 */
async function getTokenEmailCollection(upns: string[]): Promise<EmailAssertionPair[]> {
	if (!upns.length) throw new StorageError('getTokenEmailCollection: upns not provided');

	const returnItems: EmailAssertionPair[] = [];

	for (let i = 0; i < upns.length; i += 1) {
		returnItems.push(<EmailAssertionPair>{
			// we need these to to run synchronously because office
			// gets angry if we end up requesting the same token twice
			// eslint-disable-next-line no-await-in-loop
			Assertion: await getGraphToken(upns[i]),
			EmailAddress: upns[i]
		});
	}
	return returnItems;
}

/**
 * Stores the given esi token
 * @param {string} data The encoded data string
 * @param {string} expiresAtUtc A UTC string representation of the expiry
 * @returns {void}
 */
async function storeEsiToken(data: string, expiresAtUtc: string | null): Promise<void> {
	const officeProxy = new OfficeProxy();
	const { emailAddress } = officeProxy.getUserProfile();
	const tokenName = `${TOKEN_NAME_ESI}-${hash(emailAddress)}`;

	const tokenStore = new TokenStore(TOKEN_STORE);
	logger.info({
		message: 'Storing new ESI Token in cache.',
		properties: { source: 'token\\index.js', method: 'storeEsiToken' }
	});
	await tokenStore.updateToken(new TokenDetail(tokenName, data, expiresAtUtc));
}

/**
 * @param tokenStore The tokenstore to avoid re-instantiation
 * @param tokenName The new tokenName to store the token
 * @returns {TokenDetail | null} the existing token or null
 */
async function resolveForBackwardCompatibility(tokenStore: TokenStore, tokenName: string): Promise<TokenDetail | null> {
	const cachedToken = await tokenStore.getToken(TOKEN_NAME_ESI);
	if (cachedToken) {
		const oldTokenDetails = new TokenDetail(tokenName, cachedToken.data, cachedToken.expiresAtUtc);
		await tokenStore.updateToken(oldTokenDetails);
		return cachedToken;
	}

	return null;
}

/**
 * Retrieves cached or renewed Esi token for the given username
 * @returns {TokenDetail | StorageError}
 */
async function getEsiTokenDetails(): Promise<TokenDetail> {
	const officeProxy = new OfficeProxy();
	const { emailAddress } = officeProxy.getUserProfile();
	const tokenName = `${TOKEN_NAME_ESI}-${hash(emailAddress)}`;

	// Check session data first
	const tokenStore = new TokenStore(TOKEN_STORE);
	let cachedToken = await tokenStore.getToken(tokenName);

	// Backwards compatibility (Todo: deleted once new token name is wide spread)
	if (!cachedToken) {
		cachedToken = await resolveForBackwardCompatibility(tokenStore, tokenName);
	}

	/* This method assumes that the caller has already tested that the user is logged in,
	 * so there should always be a token stored, though it may have expired and require renewal.
	 */
	if (!cachedToken) {
		throw new StorageError('Token was not found in storage.'
			+ `[TokenName: ${tokenName}]`);
	}

	if (cachedToken.hasExpired()) {
		logger.info({
			message: 'Esi token is expiring/expired, requesting new one.',
			properties: { source: 'token\\index.js', method: 'getEsiToken' }
		});

		const {
			data: newTokenData,
			expiresAtUtc: newTokenExpiresUtc
		} = await renewAuthTokens(cachedToken.data);

		logger.info({
			message: 'Storing new ESI Token in cache.',
			properties: { source: 'token\\index.js', method: 'getEsiToken' }
		});

		const newTokenDetails = new TokenDetail(tokenName, newTokenData, newTokenExpiresUtc);
		await tokenStore.updateToken(newTokenDetails);

		return newTokenDetails;
	}
	return cachedToken;
}

/**
 * Retrieves cached or renewed Esi token for the given username
 * @returns {TokenDetail | StorageError}
 */
async function getEsiToken(): Promise<string> {
	try {
		const cachedToken = await getEsiTokenDetails();
		return cachedToken.data;
	} catch (error) {
		throw new AuthError((error as any).message);
	}
}

/**
 * Delete the esi token in cache
 * @returns {void}
 */
async function deleteEsiToken(): Promise<void> {
	const officeProxy = new OfficeProxy();
	const { emailAddress } = officeProxy.getUserProfile();
	const tokenName = `${TOKEN_NAME_ESI}-${hash(emailAddress)}`;

	const tokenStore = new TokenStore(TOKEN_STORE);
	logger.info({
		message: 'Deleting ESI Token in cache.',
		properties: { source: 'token\\index.js', method: 'deleteEsiToken' }
	});

	await tokenStore.removeToken(tokenName);
}

export {
	getGraphToken,
	getTokenEmailCollection,
	getEsiToken,
	getEsiTokenDetails,
	storeEsiToken,
	deleteEsiToken
};
