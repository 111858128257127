import IndexedDbRepository from '@common/dbStorage/indexedDbStore';

export default class AppVersionStore {
	store: IndexedDbRepository<string>;

	launcheventsVersionKey: string = 'Launchevents';

	taskpaneVersionKey: string = 'taskpane';

	onsendVersionKey: string = 'Onsend';

	/**
	 * Create an instance of the AppVersionStore class
	 * @param {string | null} storeName Provide a name to give this config store.
	 */
	constructor() {
		this.store = new IndexedDbRepository('app-version-store', 864000000);
	}

	/**
	 * Retrieves the value of the Launchevents version
	 * @returns {string | StorageError}
	 */
	async getLauncheventsVersion(): Promise<string | null> {
		const found = await this.store.get(this.launcheventsVersionKey);
		if (!found) return null;
		return found;
	}

	/**
	 * Retrieves the value of the Onsend version
	 * @returns {string | StorageError}
	 */
	async getOnsendVersion(): Promise<string | null> {
		const found = await this.store.get(this.onsendVersionKey);
		if (!found) return null;
		return found;
	}

	/**
	 * Retrieves the value of the Taskpane version
	 * @returns {string | StorageError}
	 */
	async getTaskpaneVersion(): Promise<string | null> {
		const found = await this.store.get(this.taskpaneVersionKey);
		if (!found) return null;
		return found;
	}

	/**
	 * Sets the value of the Launchevents version
	 * @param {string} val The value to update
	 * @returns {string | StorageError}
	 */
	async setLauncheventsVersion(val: string): Promise<void> {
		await this.store.update(this.launcheventsVersionKey, val);
	}

	/**
	 * Sets the value of the Onsend version
	 * @param {string} val The value to update
	 * @returns {string | StorageError}
	 */
	async setOnsendVersion(val: string): Promise<void> {
		await this.store.update(this.onsendVersionKey, val);
	}

	/**
	 * Sets the value of the Taskpane version
	 * @param {string} val The value to update
	 * @returns {string | StorageError}
	 */
	async setTaskpaneVersion(val: string): Promise<void> {
		await this.store.update(this.taskpaneVersionKey, val);
	}

	/**
	 * Drops the instance of the created store.
	 * @returns {void | StorageError}
	 */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}
}
