class Timer {
	name: string;

	startTime: number;

	stopTime: number;

	isRunning: boolean;

	additionalInfo: {};

	constructor(name: string, additionalInfo: {}) {
		this.name = name;
		this.startTime = 0;
		this.stopTime = 0;
		this.isRunning = false;
		this.additionalInfo = additionalInfo;
	}

	static startNew(name: string, additionalInfo: {} = {}): Timer {
		const result = new Timer(name, additionalInfo);
		result.start();
		return result;
	}

	start(): void {
		if (this.isRunning) return;
		this.startTime = performance.now();
		this.isRunning = true;
	}

	stop(): void {
		if (!this.isRunning) return;
		this.stopTime = performance.now();
		this.isRunning = false;
	}

	resume(): void {
		if (this.isRunning) return;
		this.startTime += performance.now() - this.stopTime;
		this.isRunning = true;
	}

	pause(): void {
		this.stop();
	}

	addAdditionalInfo(infoToAdd : {}) {
		this.additionalInfo = {
			...this.additionalInfo,
			...infoToAdd
		};
	}

	completed(): boolean {
		return this.stopTime > 0 && !this.isRunning;
	}

	getElapsed(): number {
		if (this.isRunning) return performance.now() - this.startTime;
		return this.stopTime - this.startTime;
	}
}

export default Timer;
