<script lang='ts'>
import Vue from 'vue';
import { theme } from '@common/enums';
import MessagePrompt from '@/views/dlpPrompts/MessagePrompt.vue';
import RequestTagPrompt from '@/views/dlpPrompts/RequestTagPrompt.vue';
import RequestLabelPrompt from '@/views/dlpPrompts/RequestLabelPrompt.vue';
import PromptDataTransferStore from '@common/dbStorage/promptDataTransferStore';

export default Vue.extend({
	name: 'PromptApp',
	data() {
		return {
			darkModeEnabled: false,
			ComponentProps: {
				msg: '',
				theme: '',
				matches: '',
				countdown: '300',
				appVersion: '',
				rlds: '',
				rtds: ''
			},
			loaded: false
		};
	},
	components: {
		MessagePrompt,
		RequestTagPrompt,
		RequestLabelPrompt
	},
	methods: {
		async setUserTheme() {
			const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
			const promptTime = urlParams.get('promptTime');
			const promptDataStore = new PromptDataTransferStore();
			const promptData = await promptDataStore.getPromptDataTransfer(promptTime ?? '');
			this.darkModeEnabled = (promptData?.theme ?? urlParams.get('theme')) === theme.Dark;
		},
		async GetComponentProps(): Promise<any> {
			const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
			const promptTime = urlParams.get('promptTime');
			const promptDataStore = new PromptDataTransferStore();
			const promptData = await promptDataStore.getPromptDataTransfer(promptTime ?? '');

			if (this.isMessagePrompt) {
				return {
					msg: promptData?.msg ?? urlParams.get('msg'),
					theme: promptData?.theme ?? urlParams.get('theme'),
					matches: promptData?.matches ?? urlParams.get('matches'),
					countdown: promptData?.countDown ?? Number.parseInt(urlParams.get('countdown') ?? '0', 10),
					appVersion: promptData?.appVersion ?? urlParams.get('appversion')
				};
			}
			if (this.isRequestTagPrompt) {
				return {
					rtds: promptData?.rtds ?? urlParams.get('rtds'),
					theme: promptData?.theme ?? urlParams.get('theme'),
					countdown: promptData?.countDown ?? Number.parseInt(urlParams.get('countdown') ?? '0', 10)
				};
			}
			if (this.isRequestLabelPrompt) {
				return {
					rlds: promptData?.rlds ?? urlParams.get('rlds'),
					theme: promptData?.theme ?? urlParams.get('theme'),
					countdown: promptData?.countDown ?? Number.parseInt(urlParams.get('countdown') ?? '0', 10),
					userLabels: promptData?.userLabels ?? urlParams.get('userlabels')
				};
			}

			// only prompt call this top level component so should not get here
			return {};
		}
	},
	computed: {
		isMessagePrompt(): any {
			return window.location.toString().includes('/prompts/message');
		},
		isRequestLabelPrompt(): any {
			return window.location.toString().includes('/prompts/requestLabel');
		},
		isRequestTagPrompt(): any {
			return window.location.toString().includes('/prompts/requestTag');
		},
		ComponentToRender(): any {
			if (this.isMessagePrompt) {
				return MessagePrompt;
			}
			if (this.isRequestTagPrompt) {
				return RequestTagPrompt;
			}
			if (this.isRequestLabelPrompt) {
				return RequestLabelPrompt;
			}

			// default
			return MessagePrompt;
		}
	},
	async beforeMount() {
		this.ComponentProps = await this.GetComponentProps();
		this.loaded = true;
	},
	mounted() {
		this.setUserTheme();
	}
});
</script>

<template>
  <div
    v-if="this.loaded"
    id="app"
    :class="{ 'theme-dark': darkModeEnabled, 'theme-light': !darkModeEnabled }">
    <transition
      name="fade"
      mode="out-in">
      <component :is="ComponentToRender" v-bind="ComponentProps" />
    </transition>
  </div>
</template>

<style lang="scss">
html, body {
	font-size: 14px !important;
}

div#app.theme-dark {
	border-top: none;
	margin-top: unset;
}
div#app {
	border-top: 1px solid lightgray;
	margin-top: -1px;
}
</style>
