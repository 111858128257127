export default {
	orgId: 'https://egress.com/messageapi/claims/orgid',
	orgName: 'https://egress.com/messageapi/claims/orgname',
	esiAccessToken: 'https://egress.com/office/claims/esiaccesstoken',
	esiRefreshToken: 'https://egress.com/office/claims/esirefreshtoken',
	esiServer: 'https://egress.com/office/claims/esiserver',
	esiSessionId: 'https://egress.com/office/claims/esisessionid',
	rbpServiceEndpoint: 'https://egress.com/office/claims/rbpserviceendpoint',
	rbpTimeout: 'https://egress.com/office/claims/rbptimeout',
	sessionKey: 'https://egress.com/office/claims/sessionkey',
	sid: 'https://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'
};
