import { theme } from '@common/enums';
import { UIUserInterface } from '@/interfaces';

function isOutlookThemeDark({ officeTheme }: any): boolean {
	if (!officeTheme?.bodyBackgroundColor) {
		// officeTheme is only supported in Office on Windows
		return false;
	}

	const colour = officeTheme.bodyBackgroundColor;
	const r = parseInt(colour.substr(1, 2), 16);
	const g = parseInt(colour.substr(3, 2), 16);
	const b = parseInt(colour.substr(5, 2), 16);
	// 50% grey -> 127+127+127 = 381;
	return r + g + b < 381;
}

export default {
	darkModeEnabled: (state: UIUserInterface) => {
		switch (state.theme) {
		case theme.Dark:
			return true;
		case theme.Light:
			return false;
		case theme.Automatic:
		default:
			return isOutlookThemeDark(state);
		}
	},
	switchId: (state: UIUserInterface) => state.switchId,
	isPreventClient: (state: UIUserInterface) => state.isPreventClient,
	isProtectClient: (state: UIUserInterface) => state.isProtectClient,
	largeFileTransferEnabled: (state: UIUserInterface) => state.largeFileTransferEnabled,
	shouldEncryptCompleteMessage: (state: UIUserInterface) => state.shouldEncryptCompleteMessage,
	useMimeBuilder: (state: UIUserInterface) => state.useMimeBuilder,
	useNaaGraphApi: (state: UIUserInterface) => state.useNaaGraphApi,
	refreshAfterUtc: (state: UIUserInterface) => state.refreshAfterUtc,
	organization: (state: UIUserInterface) => state.organization,
	server: (state: UIUserInterface) => state.server
};
