import { createNestablePublicClientApplication, type IPublicClientApplication } from '@azure/msal-browser';
import getAppConfigInfo from '@common/httpClient/config/getAppConfigInfo';

const msalConfig = {
	auth: {
		clientId: '',
		authority: 'https://login.microsoftonline.com/common',
		supportsNestedAppAuth: true
	}
};
let scopes: string[] = [];

// Encapsulate functions for getting user account and token information.
export default class AccountManager {
	pca: IPublicClientApplication | undefined = undefined;

	// Initialize MSAL public client application.
	async initialize() {
		let appConfig = await getAppConfigInfo();
		if (!appConfig.graphClientInfo?.clientId) {
			appConfig = await getAppConfigInfo(true);
		}
		if (!appConfig.graphClientInfo?.clientId) {
			throw new Error('graph client info not available in config');
		}
		msalConfig.auth.clientId = appConfig.graphClientInfo.clientId;
		scopes = appConfig.graphClientInfo.scopes;
		this.pca = await createNestablePublicClientApplication(msalConfig);
	}

	async ssoGetToken() {
		const userAccount = await this.ssoGetUserIdentity();
		return userAccount.accessToken;
	}

	/**
     * Uses MSAL and nested app authentication to get the user account from Office SSO.
     * This demonstrates how to work with user identity from the token.
     *
     * @returns The user account data (identity).
     */
	async ssoGetUserIdentity() {
		if (this.pca === undefined) {
			throw new Error('AccountManager is not initialized!');
		}

		// Specify minimum scopes needed for the access token.
		const tokenRequest = {
			scopes
		};

		try {
			console.log('Trying to acquire token silently...');
			const userAccount = await this.pca.acquireTokenSilent(tokenRequest);
			console.log('Acquired token silently.');
			return userAccount;
		} catch (error) {
			console.log(`Unable to acquire token silently: ${error}`);
		}

		// Acquire token silent failure. Send an interactive request via popup.
		try {
			console.log('Trying to acquire token interactively...');
			const userAccount = await this.pca.acquireTokenPopup(tokenRequest);
			console.log('Acquired token interactively.');
			return userAccount;
		} catch (popupError) {
			// Acquire token interactive failure.
			console.log(`Unable to acquire token interactively: ${popupError}`);
			throw new Error(`Unable to acquire access token: ${popupError}`);
		}
	}
}
