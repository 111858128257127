/* eslint-disable no-bitwise */
import AnalyticJourneyId from '@common/enums/analyticJourneyId';
/**
 * @readonly
 * @enum {number}
 */
const protectionInfoIconType = Object.freeze({
	Unknown: 0,
	Information: 1,
	Error: 2,
	Warning: 3
});

/**
 * @readonly
 * @enum {number}
 */
const protectionInfoActionType = Object.freeze({
	None: 0,
	AllToBcc: 1
});

/**
 * @readonly
 * @enum {number}
 */
const riskLevel = Object.freeze({
	Unknown: 0,
	Low: 1,
	Medium: 2,
	High: 3
});

/**
 * @readonly
 * @enum {number}
 */
const recipientActionType = Object.freeze({
	None: 0,
	Delete: 1,
	Replace: 2
});

/**
 * @readonly
 * @enum {number}
 */
const advicePriority = Object.freeze({
	None: 0,
	Verified: 1,
	NewRecipients: 2,
	Unverified: 3
});

/**
 * @readonly
 * @enum {number}
 */
const rbpState = Object.freeze({
	Suppressed: 1,
	Empty: 2,
	Error: 3
});

/**
 * @readonly
 * @enum {string}
 */
const rbpOnSendErrors = Object.freeze({
	Forbidden: 'FORBIDDEN',
	AuthRequired: 'AUTH_REQUIRED',
	Error: 'ERROR'
});

/**
 * @readonly
 * @enum {string}
 */
const theme = Object.freeze({
	Automatic: 'AUTOMATIC',
	Light: 'LIGHT',
	Dark: 'DARK'
});

enum OnSendErrorMode {
	Block = 'BLOCK',
	Allow = 'ALLOW'
}

enum RbpAuditOptions {
	None = 0,
	DlpLog = 1, // 1
	Subject = 1 << 1, // 2
	Captures = 1 << 2, // 4
	SubjectHash = 1 << 3, // 8
	DLPVebose = 1 << 4 // 16
}

enum DemAction {
	RequestSecurityLabel,
	RequestTag,
	ShowMessage,
	SetLabel,
	SetTag,
	BlockSend
}

enum AttachmentProcessingStatus {
	Queued,
	Processing,
	Processed,
	Cancelled,
	Failed
}

enum AttachmentSource {
	Native = 0,
	LftPanel = 100
}

enum AuthenticationMode {
	Manual = 'MANUAL',
	Auto = 'AUTO'
}

enum RequestTagPromptInputType {
	Button,
	Dropdown,
	Freetext
}
enum RbpOpinionRequestStatus {
	Okay,
	Failed,
	TimedOut
}
enum LftUploadCurrentStatus {
	Okay,
	InProgress,
	UnresolvedError
}

enum ContentTransferEncodingType {
	QuotedPrintable,
	Base64
}
enum ApplicationType {
	CloudDemEngine,
	AttachmentProcessor,
	LftProcessor
}

export {
	protectionInfoIconType,
	protectionInfoActionType,
	riskLevel,
	recipientActionType,
	advicePriority,
	rbpState,
	rbpOnSendErrors,
	theme,
	RbpAuditOptions,
	DemAction,
	AttachmentProcessingStatus,
	AttachmentSource,
	OnSendErrorMode,
	AuthenticationMode,
	AnalyticJourneyId,
	RequestTagPromptInputType,
	RbpOpinionRequestStatus,
	LftUploadCurrentStatus,
	ContentTransferEncodingType,
	ApplicationType
};
