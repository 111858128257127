<script lang='ts'>
import Vue from 'vue';
import { theme } from '@common/enums';
import { OfficeProxy } from '@egress/officejs-proxy';
import { DIALOG_CLOSE, DIALOG_LABELSELECTED } from '@common/constants';
import { CapturedMatchStructure, LabelsInterface } from '@common/interfaces';
import { Base64 } from 'js-base64';
import goToExternalLink from '@common/utils/externalLink';
import EdsButton from '@/components/eds/Button.vue';
import EdsSelect from '@/components/eds/Select.vue';
import CapturedMatchesTable from '@/components/dem/CapturedMatchesTable.vue';
import egressLogoLight from '@/assets/img/eds-light-logo.svg';
import egressLogoDark from '@/assets/img/eds-dark-logo.svg';
import PromptCountdownTimer from '@/views/dlpPrompts/PromptCountdownTimer.vue';
import { EdsSelectEventOutput } from '@/interfaces';

export default Vue.extend({
	name: 'RequestLabelPrompt',
	data() {
		return {
			parentMessageReceivedHandlerRegistered: false,
			hasSwitchHeaderError: false,
			loadError: false,
			selectedLabelId: '',
			securityLabelId: '',
			labels: {} as LabelsInterface
		};
	},
	props: {
		countdown: {
			type: Number,
			required: true
		},
		rlds: {
			type: String,
			default: null
		},
		userLabels: {
			type: String,
			default: null
		},
		theme: {
			type: String,
			required: true
		}
	},
	components: {
		EdsButton,
		EdsSelect,
		CapturedMatchesTable,
		PromptCountdownTimer
	},
	methods: {
		onClickOverride(event: any) {
			if (event.target.tagName.toLowerCase() === 'a') {
				goToExternalLink(event.target.href);
			}
		},
		setInitialLabel(): void {
			const defaultLabel = this.defaultEmailLabelId;

			if (defaultLabel) {
				this.selectedLabelId = defaultLabel;
			} else if (this.decodedLabelRequest?.securityLabelIds?.length === 1) {
				this.selectedLabelId = this.decodedLabelRequest?.securityLabelIds[0];
			}
		},
		async handleLabelChange(value: EdsSelectEventOutput): Promise<void> {
			this.selectedLabelId = value.id;
		},
		async closePrompt(): Promise<void> {
			OfficeProxy.messageParent(JSON.stringify({
				type: DIALOG_CLOSE
			}));
		},
		async confirmSelectedLabel(): Promise<void> {
			OfficeProxy.messageParent(JSON.stringify({
				type: DIALOG_LABELSELECTED,
				selectedValue: this.selectedLabelId
			}));
		}
	},
	computed: {
		classifications(): any {
			return this.decodedUserLabels?.classifications;
		},
		classificationLabels(): any {
			return this.classifications
				?.filter((classificationValue : any) => this.decodedLabelRequest?.securityLabelIds
					.some((demLabelValue: any) => demLabelValue === classificationValue.id));
		},
		darkModeEnabled(): boolean {
			return this.theme === theme.Dark;
		},
		decodedLabelRequest(): any {
			if (this.rlds.length) {
				return JSON.parse(Base64.decode(this.rlds));
			}
			return {};
		},
		decodedUserLabels(): any {
			if (this.userLabels.length) {
				return JSON.parse(Base64.decode(this.userLabels));
			}
			return {};
		},
		getEgressLogoSrc(): any {
			return (this.theme === theme.Dark)
				? egressLogoDark
				: egressLogoLight;
		},
		capturedMatches(): CapturedMatchStructure[] | undefined {
			return this.decodedLabelRequest?.capturedMatches ?? [];
		},
		defaultEmailLabelId(): any {
			if (this.decodedLabelRequest?.selectedIndex
			&& this.decodedLabelRequest?.selectedIndex > 0
			&& this.decodedLabelRequest?.selectedIndex < this.classificationLabels.length) {
				return this.getLabelOptions[this.decodedLabelRequest?.selectedIndex].id;
			}
			return '';
		},
		requestLabelTitle(): any {
			return this.decodedLabelRequest?.title;
		},
		requestLabelSubtitle(): any {
			return this.decodedLabelRequest?.subtitle;
		},
		getLabelOptions(): any {
			return this.classificationLabels ?? [];
		},
		getDisplayLabelOptions(): any {
			const placeholder = [{
				id: '',
				selected: true,
				hidden: true,
				name: this.$t('labelSelect.placeholder')
			}];

			const formatedOptions = this.getLabelOptions.map((l: any) => {
				const cutOffCount = 80;
				if (l.name.length > cutOffCount) {
					const cutOffPoint = l.name.substring(0, cutOffCount);
					const whiteSpaces = cutOffPoint.split(' ').length;
					return { ...l, name: `${l.name.substring(0, cutOffCount + whiteSpaces)} ...` };
				}
				return l;
			});

			return [...placeholder, ...formatedOptions];
		}
	},
	async mounted(): Promise<void> {
		if (!this.rlds || !this.userLabels) {
			this.loadError = true;
		}
		this.setInitialLabel();
	}
});
</script>

<template>
  <main>
    <div class="container">
      <div class="row">
        <div id="request-label-prompt" class="dlp-prompt">
          <PromptCountdownTimer
            :darkModeEnabled="this.darkModeEnabled"
            :timeRemaining="this.countdown"
            @timerExpired="this.closePrompt" />
          <div v-if="!loadError">
            <div>
              <img
                :src="getEgressLogoSrc"
                alt="egressIcon"
                aria-hidden="true"
              >
            </div>
            <div class="dlp-title pt-3" v-html="requestLabelTitle" />
            <div
              class="dlp-info py-3"
              v-html="requestLabelSubtitle"
              v-on:click.prevent="onClickOverride($event)"
              v-on:keydown="onClickOverride($event)"
            />
            <fieldset>
              <EdsSelect
                class="py-3"
                id="dem-select"
                v-model.lazy="selectedLabelId"
                label=""
                :options="getDisplayLabelOptions"
                @input="handleLabelChange"
                @change="handleLabelChange"
              />
              <CapturedMatchesTable
                class="py-3"
                v-if="this.capturedMatches.length"
                :matches="this.capturedMatches" />
              <div class="base mt-4 mb-4">
                <div class="controls">
                  <EdsButton
                    id="btn-send-ok"
                    class="mr-3"
                    buttonType="prime"
                    type="submit"
                    :disabled="selectedLabelId === ''"
                    @click="confirmSelectedLabel">
                    {{ $t('common.ok') }}
                  </EdsButton>
                  <EdsButton
                    id="btn-send-cancel"
                    buttonType="secondary"
                    @click="closePrompt">
                    {{ $t('common.cancel') }}
                  </EdsButton>
                </div>
              </div>
            </fieldset>
          </div>
          <div v-if="loadError">
            <p id="load-error" class="eds-form-error-text d-block m2">
              {{ $t('prompt.loadError') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
