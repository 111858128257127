<script lang='ts'>
import { mapGetters, mapActions } from 'vuex';
import { UPDATE_OFFICE_THEME } from '@/store/types';
import authMixin from '@/mixins/authMixin';
import AppVersionStore from '@common/dbStorage/appVersionStore';

export default authMixin.extend({
	name: 'OfficeJSApp',
	data() {
		return {
			storagePoll: 0,
			officeThemePoll: 0,
			isReady: false
		};
	},
	methods: {
		...mapActions([UPDATE_OFFICE_THEME]),
		async pollOfficeTheme() {
			await this[UPDATE_OFFICE_THEME]();
		},
		async updateTaskpaneVersion() {
			const appVersionStore = new AppVersionStore();
			await appVersionStore.setTaskpaneVersion(this.$store?.state?.vueAppVersion ?? '');
		}
	},
	computed: {
		...mapGetters(['darkModeEnabled', 'isAuthenticated'])
	},
	async mounted() {
		await this.pollOfficeTheme();
		this.storagePoll = window.setInterval(this.pollStorage, 1000);
		this.officeThemePoll = window.setInterval(this.pollOfficeTheme, 1000);

		await this.updateTaskpaneVersion();

		window.addEventListener('beforeunload', () => {
			clearInterval(this.storagePoll);
			clearInterval(this.officeThemePoll);
		});
	},
	beforeDestroy() {
		clearInterval(this.storagePoll);
	}
});
</script>

<template>
  <div
    id="app"
    :class="{ 'theme-dark': darkModeEnabled, 'theme-light': !darkModeEnabled }">
    <transition
      name="fade"
      mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<style lang="scss">
html, body {
	font-size: 14px !important;
}

div#app.theme-dark {
	border-top: none;
	margin-top: unset;
}
div#app {
	border-top: 1px solid lightgray;
	margin-top: -1px;
}
</style>
